import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Picture from '@/components/Picture';
import Link from '@/components/Link';
import CameraIcon from '@/assets/svg/camera.svg';
import CompareCheckbox from '@/components/CompareCheckbox';
import CarCurrentState from '@/components/CarCurrentState';
import { colors } from '@/constants/theme';
import CarApproved from '@/components/CarApproved';
import CarStatus from '@/components/CarStatus';
import CarGrade from '@/components/CarGrade';

import { StockCardSliderProps } from './types';

function StockCardSlider({
  images,
  view,
  link,
  totalImagesCount,
  isCompared,
  onCompareChange,
  carStatus,
  stockStatus,
  approved,
  grade,
  alt,
  isLaptop,
}: StockCardSliderProps) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const slides = images.map((item, index) => {
    return (
      <SlideListItem
        key={index}
        onMouseEnter={() => {
          setActiveSlideIndex(index);
        }}
        isActive={activeSlideIndex === index}
      >
        <StyledPicture
          {...convertThumbnailToPictureProps(item)}
          loading="lazy"
          alt={`${alt ?? 'stock card image'} #${index + 1}`}
        />
      </SlideListItem>
    );
  });

  return (
    <Component
      view={view}
      onMouseOut={() => {
        setActiveSlideIndex(0);
      }}
    >
      {link ? (
        <SlideListLink to={link}>{slides}</SlideListLink>
      ) : (
        <SlideList>{slides}</SlideList>
      )}

      {images.length > 1 ? (
        <PaginationList>
          {images.map((item, index) => {
            return (
              <PaginationListItem
                key={index}
                isActive={activeSlideIndex === index}
                isLaptop={isLaptop}
              />
            );
          })}
        </PaginationList>
      ) : null}

      {totalImagesCount &&
      activeSlideIndex === images.length - 1 &&
      totalImagesCount - images.length ? (
        <MorePhotosSlide>
          <CameraIcon />
          <MorePhotosTitle>
            +{totalImagesCount - images.length} фото
          </MorePhotosTitle>
        </MorePhotosSlide>
      ) : null}

      {onCompareChange && (
        <CheckboxWrapper>
          <CompareCheckbox checked={!!isCompared} onChange={onCompareChange} />
        </CheckboxWrapper>
      )}

      {stockStatus && (
        <StockStatusWrapper>
          <CarStatus carStatus={stockStatus} isSmall />
        </StockStatusWrapper>
      )}

      {carStatus ? (
        <CarCurrentStateWrapper>
          <CarCurrentState text={carStatus} />
        </CarCurrentStateWrapper>
      ) : approved ? (
        <CarApprovedWrapper>
          <CarApproved />
        </CarApprovedWrapper>
      ) : grade ? (
        <CarGradeWrapper>
          <CarGrade grade={grade} />
        </CarGradeWrapper>
      ) : null}
    </Component>
  );
}

export default StockCardSlider;

export const Component = styled.div<{
  view?: 'landscape' | 'portrait';
}>`
  position: relative;
  background-color: #efefef;
  padding-top: 65.481%;

  ${({ view }) =>
    view === 'portrait'
      ? css`
          border-radius: 8px 8px 0 0;
        `
      : css`
          border-radius: 8px 0 0 8px;
        `}
`;

const SlideListStyles = css`
  z-index: 1;
  display: flex;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
`;

const SlideListLink = styled(Link)`
  ${SlideListStyles}
`;

const SlideList = styled.div`
  ${SlideListStyles}
`;

const SlideListItem = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  flex: 1;

  ${({ isActive }) =>
    isActive &&
    css`
      ${StyledPicture} {
        display: block;
        z-index: -1;
      }
    `}
`;

export const StyledPicture = styled(Picture)`
  display: none;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  picture,
  img {
    width: 100%;
    height: 100%;
    border-radius: 8px 8px 0 0;
    object-fit: cover;
  }
`;

const PaginationList = styled.div`
  z-index: 1;
  display: flex;
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
`;

const PaginationListItem = styled.div<{
  isActive: boolean;
  isLaptop?: boolean;
}>`
  width: 40px;
  height: 4px;
  background-color: ${colors.black};
  border-radius: 3px;

  &:not(:last-child) {
    margin-right: 5px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${colors.white};
    `}

  ${({ isLaptop }) =>
    isLaptop &&
    css`
      width: 35px;
    `}
`;

const MorePhotosSlide = styled.div`
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
`;

const MorePhotosTitle = styled.h2`
  margin-top: 20px;
  color: ${colors.white};
`;

const CheckboxWrapper = styled.div`
  z-index: 1;
  position: absolute;
  top: 5px;
  left: 5px;
`;

const CarCurrentStateWrapper = styled.div`
  z-index: 1;
  position: absolute;
  right: 5px;
  bottom: 0;
  transform: translateY(50%);
`;

const StockStatusWrapper = styled.div`
  z-index: 1;
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 7px;
  height: 25px;
  background: ${colors.white};
  border-radius: 5px;
`;

const CarApprovedWrapper = styled.div`
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(50%);
`;

const CarGradeWrapper = styled.div`
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(50%);
`;
