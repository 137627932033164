import React from 'react';
import styled, { css } from 'styled-components';
import Tippy from '@tippyjs/react';

import { isBrowser } from '@tager/web-core';

import InfoIcon from '@/assets/svg/info.svg';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import { CarGradeProps, CarGradeContentProps } from './types';

const gradients = {
  Gold: 'linear-gradient(45deg, #C69B00 -0.11%, #F9C611 99.89%)',
  Silver: 'linear-gradient(45deg, #959595 0%, #BEBEBE 100%)',
  Bronze: 'linear-gradient(45deg, #B8580D 0%, #F29750 100%)',
};

const Content = ({ description }: CarGradeContentProps) => {
  return (
    <ContentComponent>
      <ContentComponentContainer>
        {description.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ContentComponentContainer>
    </ContentComponent>
  );
};

const CarGrade = ({ grade }: CarGradeProps) => {
  if (grade.description && grade.description.length > 0) {
    return (
      <Tippy
        content={<Content description={grade.description} />}
        placement="bottom"
        interactive
        appendTo={isBrowser() ? document.body : 'parent'}
      >
        <Component
          gradient={gradients[grade.name] ?? gradients.Bronze}
          withHover
        >
          {grade.name}
          <InfoIcon />
        </Component>
      </Tippy>
    );
  } else {
    return (
      <Component gradient={gradients[grade.name] ?? gradients.Bronze}>
        {grade.name}
      </Component>
    );
  }
};

export default CarGrade;

const ContentComponent = styled.div`
  background: ${colors.white};
  border: 0.5px solid ${colors.gray400};
  box-shadow: ${(props) => props.theme.blackShadow100};
  border-radius: 8px;
`;

const ContentComponentContainer = styled.ul`
  padding: 10px;
  font-weight: 300;
  font-size: 14px;
  line-height: 130%;
  color: ${colors.black};

  ${media.mobile(css`
    font-size: 13px;
    line-height: 130%;
  `)}

  li {
    padding-left: 10px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 8px;
      width: 4px;
      height: 4px;
      background: ${colors.black};
      border-radius: 100%;

      ${media.mobile(css`
        top: 7px;
      `)}
    }
  }
`;

const Component = styled.div<{ gradient: string; withHover?: boolean }>`
  height: 36px;
  padding: 0 8px 0 25px;
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.white};
  position: relative;
  transition: 0.3s all ease;
  background: ${(props) => props.gradient};
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 13px 50%);

  ${media.mobile(css`
    height: 28px;
    font-size: 13px;
    line-height: 17px;
  `)}

  ${({ withHover }) =>
    withHover &&
    css`
      &:hover {
        opacity: 0.9;
      }
    `}

  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 18px 15px 18px 0;
    border-color: transparent transparent transparent transparent;
  }

  svg {
    margin-left: 6px;

    path:first-child {
      stroke: ${colors.white};
    }

    path:not(:first-child) {
      fill: ${colors.white};
    }

    ${media.mobile(css`
      width: 13px;
      height: 13px;
    `)}
  }
`;
