import React from 'react';
import styled, { css } from 'styled-components';

import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { ContentContainerSmall } from '@/components/ContentContainer';
import NotFoundImage from '@/assets/images/not-found-page/404-not-found.png';
import useSettingItem from '@/hooks/useSettingItem';
import { media } from '@/utils/mixin';
import { ButtonNextLink } from '@/components/Button';

function NotFound() {
  const error = useSettingItem('NOT_FOUND_PAGE_ERROR');
  const title = useSettingItem('NOT_FOUND_PAGE_TITLE');
  const subtitle = useSettingItem('NOT_FOUND_PAGE_SUBTITLE');
  const buttonText = useSettingItem('NOT_FOUND_PAGE_BUTTON');

  return (
    <Component>
      <Container>
        <Text>
          {error && <Error>{error}</Error>}
          {title && <Title dangerouslySetInnerHTML={{ __html: title }} />}
          {subtitle && (
            <Description dangerouslySetInnerHTML={{ __html: subtitle }} />
          )}

          <LinkWrapper>
            <StyledButtonNextLink variant="contained" to="/">
              {buttonText}
            </StyledButtonNextLink>
          </LinkWrapper>
        </Text>
        <Image loading="lazy" src={NotFoundImage} />
      </Container>
    </Component>
  );
}

export default NotFound;

const Component = styled.div`
  padding: 40px 0;
  background: ${(props) => props.theme.white};
`;

const Container = styled(ContentContainerSmall)`
  display: flex;
  align-items: center;

  ${media.tablet(css`
    flex-direction: column;
  `)}
`;

const Text = styled.div`
  flex: 0 0 450px;
  max-width: 450px;

  ${media.tablet(css`
    flex: auto;
    max-width: none;
  `)}
`;

const Image = styled(Picture)`
  margin-left: 50px;

  ${media.tablet(css`
    margin-left: 0;
    margin-top: 20px;
  `)}
`;

const Error = styled.div`
  font-weight: 700;
  font-size: 55px;
  line-height: 130%;
  text-transform: uppercase;
  color: ${colors.main};

  ${media.mobile(css`
    font-size: 37px;
  `)}
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 130%;
  margin-top: 40px;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    font-size: 26px;
    margin-top: 30px;
  `)}

  a {
    color: ${colors.main};

    &:hover {
      color: ${colors.main100};
    }
  }
`;

const Description = styled.div`
  font-size: 16px;
  margin: 10px 0;
  color: ${(props) => props.theme.black};

  a {
    color: ${colors.main};

    &:hover {
      color: ${colors.main100};
    }
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  margin-top: 40px;
`;

const StyledButtonNextLink = styled(ButtonNextLink)`
  width: auto;
`;
