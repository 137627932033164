import React from 'react';
import styled from 'styled-components';

import ShareIcon from '@/assets/svg/share.svg';
import TelegramIcon from '@/assets/svg/socials/tlgrm.svg';
import ViberIcon from '@/assets/svg/socials/viber.svg';
import VkIcon from '@/assets/svg/socials/vk.svg';
import FacebookIcon from '@/assets/svg/socials/facebook.svg';
import share, { SocialType } from '@/utils/share';
import { colors } from '@/constants/theme';

import { ShareProps } from './types';

function Share({ className, path, image }: ShareProps) {
  function onClick(type: SocialType) {
    return () => {
      share(type, path, image);
    };
  }

  return (
    <Dropdown className={className}>
      <DropdownToggle type="button">
        <ShareIcon />
      </DropdownToggle>
      <DropDownMenu>
        <SocialList>
          <Social onClick={onClick('tg')}>
            <TelegramIcon />
          </Social>
          <Social onClick={onClick('viber')}>
            <ViberIcon />
          </Social>
          <Social onClick={onClick('vk')}>
            <VkIcon />
          </Social>
          <Social onClick={onClick('facebook')}>
            <FacebookIcon />
          </Social>
        </SocialList>
      </DropDownMenu>
    </Dropdown>
  );
}

export default Share;

const Social = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  background: ${colors.gray100};
  border-radius: 100%;
  color: ${colors.black200};
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &:hover {
    color: ${colors.main};
    background-color: ${colors.blue400};
  }

  svg {
    width: 15px;
    height: 15px;

    path {
      fill: currentColor;
    }
  }
`;

export const DropdownToggle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.black100};
  transition: color 0.15s ease-in-out;

  svg {
    fill: currentColor;
  }
`;

const SocialList = styled.div`
  display: flex;
  align-items: center;
  background: ${colors.white};
  border: 0.2px solid ${colors.gray400};
  box-shadow: ${(props) => props.theme.blackShadow100};
  border-radius: 36px;
  padding: 10px;
`;

export const DropDownMenu = styled.div`
  display: none;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  z-index: 1;
  padding-left: 3px;
`;

const Dropdown = styled.div`
  position: relative;
  z-index: 2;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    ${DropdownToggle} {
      color: ${colors.main};
    }

    ${DropDownMenu} {
      display: block;
    }
  }
`;
