import React from 'react';
import styled, { css } from 'styled-components';
import Tippy from '@tippyjs/react';

import { isBrowser } from '@tager/web-core';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import InfoIcon from '@/assets/svg/info.svg';

import { CarCurrentStateProps } from './types';

function CarCurrentState({ text, info }: CarCurrentStateProps) {
  if (!text) {
    return null;
  }

  return (
    <Component>
      <Inner>
        <Text>{text}</Text>
        {info && (
          <Tippy
            content={<Info>{info}</Info>}
            placement="bottom"
            interactive
            appendTo={isBrowser() ? document.body : 'parent'}
          >
            <StyledInfoIcon />
          </Tippy>
        )}
      </Inner>
    </Component>
  );
}

export default CarCurrentState;

const Component = styled.div`
  display: flex;
  justify-content: center;
  padding: 6px 10px;
  border-radius: 7px;
  background: ${colors.yellow200};
`;

const Inner = styled.div`
  display: flex;
`;

const Text = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  ${media.mobile(css`
    font-size: 16px;
  `)}
`;

const StyledInfoIcon = styled(InfoIcon)`
  flex: 0 0 11px;
  width: 11px;
  height: 11px;
  margin-left: 3px;
`;

const Info = styled.div`
  width: 280px;
  padding: 15px;
  font-weight: 300;
  font-size: 14px;
  line-height: 130%;
  border-radius: 8px;
  color: ${colors.black};
  background: ${colors.white};
  border: 0.5px solid ${colors.gray400};
  box-shadow: ${(props) => props.theme.blackShadow100};
`;
