import React from 'react';
import styled from 'styled-components';

import PartnerIcon from '@/assets/svg/partner.svg';

import Badge from '../Badge';

import { BadgePartnerCarProps } from './types';

function BadgePartnerCar({
  label = 'Авто партнёра',
  isLaptop,
  isMobile,
}: BadgePartnerCarProps) {
  return (
    <StyledBadge
      label={label}
      icon={<PartnerIcon />}
      isLaptop={isLaptop}
      isMobile={isMobile}
    />
  );
}

export default BadgePartnerCar;

const StyledBadge = styled(Badge)`
  color: #343ad7;
  background-color: #dcdbff;
`;
