import React from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';

function Content() {
  return (
    <Component>
      <ComponentContainer>
        Сертифицированный автомобиль с пробегом, тщательно проверенный по 165
        пунктам программы <CarName>Land Rover Approved</CarName>.
      </ComponentContainer>
    </Component>
  );
}

export default Content;

const Component = styled.div`
  min-width: 280px;
  background: ${colors.white};
  border: 0.5px solid ${colors.gray400};
  box-shadow: ${(props) => props.theme.blackShadow100};
  border-radius: 8px;
`;

const ComponentContainer = styled.div`
  padding: 15px;
  font-weight: 300;
  font-size: 14px;
  line-height: 130%;
  color: ${colors.black};
`;

const CarName = styled.span`
  font-weight: 500;
  color: #c5a464;
`;
