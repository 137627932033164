import React from 'react';
import styled from 'styled-components';

import CardIcon from '@/assets/svg/card.svg';

import Badge from '../Badge';

import { BadgePurchaseOnlineProps } from './types';

function BadgePurchaseOnline({
  label = 'Покупка онлайн',
  isLaptop,
  isMobile,
}: BadgePurchaseOnlineProps) {
  return (
    <StyledBadge
      label={label}
      icon={<CardIcon />}
      isLaptop={isLaptop}
      isMobile={isMobile}
    />
  );
}

export default BadgePurchaseOnline;

const StyledBadge = styled(Badge)`
  color: #ac8600;
  background-color: #feffcb;
`;
