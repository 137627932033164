import React from 'react';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';

import { isBrowser } from '@tager/web-core';

import TestDriveIcon from '@/assets/svg/test_drive.svg';
import { colors } from '@/constants/theme';

import Badge from '../Badge';

import { BadgeTestDriveProps } from './types';
import ContentTestDrive from './components/ContentTestDrive';

function BadgeTestDrive({
  label = 'Тест-драйв',
  isLaptop,
  isMobile,
}: BadgeTestDriveProps) {
  return (
    <Component>
      <Tippy
        content={<ContentTestDrive />}
        placement="bottom"
        interactive
        appendTo={isBrowser() ? document.body : 'parent'}
      >
        <StyledBadge
          label={label}
          icon={<TestDriveIcon />}
          isLaptop={isLaptop}
          isMobile={isMobile}
        />
      </Tippy>
    </Component>
  );
}

export default BadgeTestDrive;

const StyledBadge = styled(Badge)`
  color: ${colors.aqua100};
  background-color: ${colors.aqua};
`;

const Component = styled.div``;
