import React from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';

import { InfoTextProps } from './types';

function InfoText({ text }: InfoTextProps) {
  return <Component>{text}</Component>;
}

export default InfoText;

const Component = styled.div`
  max-width: 280px;
  padding: 15px;
  font-weight: 300;
  font-size: 14px;
  line-height: 130%;
  border: 0.5px solid ${colors.gray400};
  box-sizing: border-box;
  box-shadow: ${(props) => props.theme.blackShadow100};
  border-radius: 8px;
  background: ${colors.white};
  color: ${colors.black};
`;
