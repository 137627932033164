import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';

export interface Props {
  value: string;
  isMobile?: boolean;
}

function NoPrice({ value, isMobile }: Props) {
  return (
    <Component
      dangerouslySetInnerHTML={{ __html: value }}
      isMobile={isMobile}
    />
  );
}

export default NoPrice;

const Component = styled.div<{ isMobile?: boolean }>`
  font-size: 16px;
  line-height: 1.5;
  color: ${(props) => props.theme.black100};
  opacity: 0.75;

  a {
    color: ${colors.main};
    border-bottom: 1px solid transparent;
    transition: 0.3s;

    &:hover {
      color: ${colors.main100};
      border-bottom: 1px solid ${colors.main100};
    }
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      font-size: 13px;
    `}
`;
