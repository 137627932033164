import React from 'react';
import styled, { css } from 'styled-components';
import Tippy from '@tippyjs/react';

import { isBrowser } from '@tager/web-core';

import EyeIcon from '@/assets/svg/detailed-car/eye.svg';
import { colors } from '@/constants/theme';

import VINContainer from './components';

interface Props {
  vin: string;
  isLaptop?: boolean;
  isMobile?: boolean;
}

function VinTag({ vin, isLaptop, isMobile }: Props) {
  return (
    <Tippy
      content={<VINContainer vin={vin} />}
      placement="bottom"
      appendTo={isBrowser() ? document.body : 'parent'}
      interactive
    >
      <Container isLaptop={isLaptop} isMobile={isMobile}>
        <EyeIcon />
        <Component isLaptop={isLaptop} isMobile={isMobile}>
          vin
        </Component>
      </Container>
    </Tippy>
  );
}

export default VinTag;

export const Container = styled.div<{ isLaptop?: boolean; isMobile?: boolean }>`
  background: ${colors.gray100};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  margin-bottom: 5px;

  ${({ isLaptop }) =>
    isLaptop &&
    css`
      padding: 0 7px;

      svg {
        width: 14px;
        height: 15px;
      }
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 0 6px;

      svg {
        width: 13px;
        height: 14px;
      }
    `}
`;

export const Component = styled.div<{ isLaptop?: boolean; isMobile?: boolean }>`
  padding: 10px 5px 10px 10px;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.black200};
  text-transform: uppercase;

  ${({ isLaptop }) =>
    isLaptop &&
    css`
      padding: 7.5px 5px 7.5px 7px;
      font-size: 12px;
      line-height: 14px;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 6px 4px 6px 5px;
      font-size: 8px;
      line-height: 9px;
    `}
`;
