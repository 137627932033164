import React from 'react';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';

import { isBrowser } from '@tager/web-core';

import CardIcon from '@/assets/svg/exclamation_point.svg';

import Badge from '../Badge';

import ContentOnlyOnline from './components/ContentOnlyOnline';
import { BadgeOnlyOnlineProps } from './types';

function BadgeOnlyOnline({
  label = 'Только онлайн',
  isLaptop,
  isMobile,
}: BadgeOnlyOnlineProps) {
  return (
    <Component>
      <Tippy
        content={<ContentOnlyOnline />}
        placement="bottom"
        interactive
        appendTo={isBrowser() ? document.body : 'parent'}
      >
        <StyledBadge
          label={label}
          icon={<CardIcon />}
          isLaptop={isLaptop}
          isMobile={isMobile}
        />
      </Tippy>
    </Component>
  );
}

export default BadgeOnlyOnline;

const Component = styled.div``;

const StyledBadge = styled(Badge)`
  color: #f48400;
  background-color: #fff9c7;
`;
