import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';

import { CarTagProps } from './CarTag.types';

function CarTag({
  title,
  background = colors.gray100,
  isMobile,
  isLaptop,
}: CarTagProps) {
  return (
    <CarTagsItemWrapper
      background={background}
      isMobile={isMobile}
      isLaptop={isLaptop}
    >
      {title}
    </CarTagsItemWrapper>
  );
}

export default CarTag;

const CarTagsItemWrapper = styled.div<{
  isLaptop?: boolean;
  isMobile?: boolean;
  background?: string;
}>`
  width: fit-content;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 5px;

  &:not(:last-child) {
    margin-right: 5px;
  }

  ${({ isLaptop }) =>
    isLaptop &&
    css`
      padding: 7.5px 7px;
      font-size: 12px;
      line-height: 14px;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 6px;
      font-size: 8px;
      line-height: 9px;
    `}

  ${({ background }) =>
    background &&
    css`
      background: ${background};
    `}
`;
