import React from 'react';
import styled, { css } from 'styled-components';
import Tippy from '@tippyjs/react';

import { isBrowser } from '@tager/web-core';

import CardIcon from '@/assets/svg/vat.svg';

import Badge from '../Badge';

import Content from './components/Content';
import { BadgeVatProps } from './types';

function BadgeVat({ label = 'НДС 20%', isLaptop, isMobile }: BadgeVatProps) {
  return (
    <Tippy
      content={
        <Content
          customStyles={css`
            span {
              color: #f48400;
            }
          `}
          text="В цене сохранен полный <span>НДС 20%</span>. Выгодный вариант для юридических лиц, а также для оформления в лизинг."
        />
      }
      placement="bottom"
      interactive
      appendTo={isBrowser() ? document.body : 'parent'}
    >
      <StyledBadge
        label={label}
        icon={<CardIcon />}
        isLaptop={isLaptop}
        isMobile={isMobile}
      />
    </Tippy>
  );
}

export default BadgeVat;

const StyledBadge = styled(Badge)`
  color: #f48400;
  background-color: #fff9c7;
`;
