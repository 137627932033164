import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { CarStatusProps, CarStatusTypes } from '@/components/CarStatus/types';

const getCarStatusName = (carStatus: CarStatusTypes) => {
  switch (carStatus) {
    case 'IN_STOCK':
      return 'В наличии';
    case 'IN_PRODUCTION':
      return 'В производстве';
    case 'PRE_ORDER':
      return 'Под заказ';
    case 'ON_WAY':
      return 'В пути';
  }
};

function CarStatus({ carStatus, isSmall }: CarStatusProps) {
  return (
    <Component>
      <Indicator carStatus={carStatus} isSmall={isSmall} />
      <Name isSmall={isSmall}>{getCarStatusName(carStatus)}</Name>
    </Component>
  );
}

export default CarStatus;

const Component = styled.div`
  display: flex;
  align-items: center;
`;

const Indicator = styled.span<{
  carStatus: CarStatusProps['carStatus'];
  isSmall?: boolean;
}>`
  flex: 0 0 12px;
  width: 12px;
  height: 12px;
  border-radius: 50%;

  ${({ carStatus }) =>
    carStatus === 'IN_STOCK'
      ? css`
          background: ${colors.green400};
        `
      : carStatus === 'ON_WAY'
      ? css`
          background: ${colors.orange100};
        `
      : carStatus === 'IN_PRODUCTION'
      ? css`
          background: ${colors.yellow100};
        `
      : carStatus === 'PRE_ORDER'
      ? css`
          background: ${colors.blue400};
        `
      : css`
          background: ${colors.white};
        `};

  ${({ isSmall }) =>
    isSmall &&
    css`
      flex: 0 0 10px;
      width: 10px;
      height: 10px;
    `}
`;

export const Name = styled.span<{ isSmall?: boolean }>`
  font-size: 15px;
  line-height: 18px;
  white-space: nowrap;
  margin-left: 7px;
  color: ${colors.black};

  ${({ isSmall }) =>
    isSmall &&
    css`
      font-size: 12px;
      line-height: 12px;
    `}
`;
