import React from 'react';
import styled from 'styled-components';

import NoveltyIcon from '@/assets/svg/novelty.svg';
import { colors } from '@/constants/theme';

import Badge from '../Badge';

import { BadgeNoveltyProps } from './types';

function BadgeNovelty({
  label = 'Новинка',
  isLaptop,
  isMobile,
}: BadgeNoveltyProps) {
  return (
    <StyledBadge
      label={label}
      icon={<NoveltyIcon />}
      isLaptop={isLaptop}
      isMobile={isMobile}
    />
  );
}

export default BadgeNovelty;

const StyledBadge = styled(Badge)`
  color: ${colors.green100};
  background: ${colors.green200};
`;
