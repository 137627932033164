import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Picture from '@/components/Picture';
import Link from '@/components/Link';
import CameraIcon from '@/assets/svg/camera.svg';
import CompareCheckbox from '@/components/CompareCheckbox';
import CarCurrentState from '@/components/CarCurrentState';
import CarApproved from '@/components/CarApproved';
import { colors } from '@/constants/theme';
import CarStatus from '@/components/CarStatus';
import CarGrade from '@/components/CarGrade';

import { MobileStockCardSliderProps } from './types';

function MobileStockCardSlider({
  link,
  images,
  totalImagesCount,
  isCompared,
  onCompareChange,
  carStatus,
  stockStatus,
  approved,
  grade,
  alt,
  isMobile,
}: MobileStockCardSliderProps) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <Component>
      <Swiper
        slidesPerView={1}
        onSlideChange={(swiper) => setActiveSlideIndex(swiper.activeIndex)}
      >
        {images.map((item, index) => {
          const image = (
            <StyledPicture
              {...convertThumbnailToPictureProps(item)}
              loading="lazy"
              alt={`${alt ?? 'stock card image'} #${index + 1}`}
            />
          );

          return (
            <SwiperSlide key={index}>
              {link ? (
                <SlideLink to={link}>{image}</SlideLink>
              ) : (
                <Slide>{image}</Slide>
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>

      {images.length > 1 ? (
        <PaginationList>
          {images.map((item, index) => {
            return (
              <PaginationListItem
                key={index}
                isActive={activeSlideIndex === index}
                isMobile={isMobile}
              />
            );
          })}
        </PaginationList>
      ) : null}

      {totalImagesCount &&
      activeSlideIndex === images.length - 1 &&
      totalImagesCount - images.length ? (
        <MorePhotosSlide>
          <CameraIcon />
          <MorePhotosTitle>
            +{totalImagesCount - images.length} фото
          </MorePhotosTitle>
        </MorePhotosSlide>
      ) : null}

      {onCompareChange && (
        <CheckboxWrapper>
          <CompareCheckbox checked={!!isCompared} onChange={onCompareChange} />
        </CheckboxWrapper>
      )}

      {stockStatus && (
        <StockStatusWrapper>
          <CarStatus carStatus={stockStatus} isSmall />
        </StockStatusWrapper>
      )}

      {carStatus ? (
        <CarCurrentStateWrapper>
          <CarCurrentState text={carStatus} />
        </CarCurrentStateWrapper>
      ) : approved ? (
        <CarApprovedWrapper>
          <CarApproved />
        </CarApprovedWrapper>
      ) : grade ? (
        <CarGradeWrapper>
          <CarGrade grade={grade} />
        </CarGradeWrapper>
      ) : null}
    </Component>
  );
}

export default MobileStockCardSlider;

const Component = styled.div`
  position: relative;
  background-color: #efefef;
  border-radius: 8px 8px 0 0;
  padding-top: 53.355%;

  .swiper-container {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
`;

const SlideStyles = css`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
`;

const SlideLink = styled(Link)`
  ${SlideStyles}
`;

const Slide = styled.div`
  ${SlideStyles}
`;

const StyledPicture = styled(Picture)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  img,
  picture {
    width: 100%;
    height: 100%;
    border-radius: 8px 8px 0 0;
    object-fit: cover;
  }
`;

const CheckboxWrapper = styled.div`
  z-index: 1;
  position: absolute;
  top: 5px;
  left: 5px;
`;

const CarApprovedWrapper = styled.div`
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(50%);
`;

const CarCurrentStateWrapper = styled.div`
  z-index: 1;
  position: absolute;
  right: 5px;
  bottom: 0;
  transform: translateY(50%);
`;

const StockStatusWrapper = styled.div`
  z-index: 1;
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 7px;
  height: 25px;
  background: ${colors.white};
  border-radius: 5px;
`;

const MorePhotosSlide = styled.div`
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
`;

const MorePhotosTitle = styled.h2`
  margin-top: 20px;
  color: ${colors.white};
`;

const PaginationList = styled.div`
  z-index: 1;
  display: flex;
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
`;

const PaginationListItem = styled.div<{
  isActive: boolean;
  isMobile?: boolean;
}>`
  width: 40px;
  height: 4px;
  background-color: ${colors.black};
  border-radius: 3px;
  
  &:not(:last-child) {
    margin-right: 5px;
  }
  
  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${colors.white};
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 30px;
    `}
  }
`;

const CarGradeWrapper = styled.div`
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(50%);
`;
