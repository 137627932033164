import React from 'react';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';

import { isBrowser } from '@tager/web-core';

import CardIcon from '@/assets/svg/guarantee.svg';
import { colors } from '@/constants/theme';

import Badge from '../Badge';

import { BadgeGuaranteeProps } from './types';

function BadgeGuarantee({
  label = 'Авто на гарантии',
  isLaptop,
  isMobile,
  hint,
}: BadgeGuaranteeProps) {
  const badge = (
    <StyledBadge
      label={label}
      icon={<CardIcon />}
      isLaptop={isLaptop}
      isMobile={isMobile}
    />
  );

  return hint ? (
    <Tippy
      content={<Content>{hint}</Content>}
      placement="bottom"
      interactive
      appendTo={isBrowser() ? document.body : 'parent'}
    >
      {badge}
    </Tippy>
  ) : (
    badge
  );
}

export default BadgeGuarantee;

const StyledBadge = styled(Badge)`
  color: #9b51e0;
  background-color: #f4e8ff;
`;

const Content = styled.div`
  max-width: 280px;
  padding: 15px;

  background: ${colors.white};
  border: 0.5px solid ${colors.gray400};
  box-sizing: border-box;
  box-shadow: ${(props) => props.theme.blackShadow100};
  border-radius: 8px;

  font-weight: 300;
  font-size: 14px;
  line-height: 130%;
  color: ${colors.black};
`;
