import React from 'react';
import styled, { css } from 'styled-components';

import Link from '@/components/Link';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { getPhoneLink } from '@/utils/common';

import { ContentProps } from './types';

function Content({ locations }: ContentProps) {
  if (!locations) {
    return null;
  }

  return (
    <Component>
      <ComponentContainer>
        {locations.map(({ phone, address }, index) => {
          return (
            <ContentWrapper key={index}>
              {address ? (
                <Group>
                  <Address>{address}</Address>
                </Group>
              ) : null}
              {phone && <PhoneLink to={getPhoneLink(phone)}>{phone}</PhoneLink>}
            </ContentWrapper>
          );
        })}
      </ComponentContainer>
    </Component>
  );
}

export default Content;

const Component = styled.div`
  text-align: left;
  z-index: 4;
  min-width: 313px;
  background: ${colors.white};
  border: 0.5px solid ${colors.gray400};
  box-shadow: ${(props) => props.theme.blackShadow100};
  border-radius: 8px;

  ${media.mobile(css`
    min-width: 280px;
  `)}
`;

const ContentWrapper = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid ${colors.gray400};

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border: none;
    padding-bottom: 0;
  }
`;

const ComponentContainer = styled.div`
  padding: 15px;
`;

const Group = styled.div`
  &:not(:last-child) {
    margin-bottom: 15px;

    ${media.mobile(css`
      margin-bottom: 10px;
    `)}
  }
`;

const Address = styled.p`
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;
  margin-top: 5px;
  color: ${colors.main};

  ${media.mobile(css`
    font-size: 12px;
    line-height: 13px;
  `)}

  &:hover {
    text-decoration: none;
  }
`;

const PhoneLink = styled(Link)`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;
  margin-top: 5px;
  color: ${colors.main};

  ${media.mobile(css`
    font-size: 12px;
    line-height: 12px;
  `)}

  &:hover {
    text-decoration: none;
  }
`;
