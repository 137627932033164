import React from 'react';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';

import { isBrowser } from '@tager/web-core';

import GuaranteeIcon from '@/assets/svg/guarantee.svg';
import { colors } from '@/constants/theme';

import Badge from '../Badge';

import { BadgeVersionProps } from './types';

function BadgeVersion({ label, hint, isLaptop, isMobile }: BadgeVersionProps) {
  return (
    <Tippy
      content={<Content>{hint}</Content>}
      placement="bottom"
      interactive
      appendTo={isBrowser() ? document.body : 'parent'}
    >
      <StyledBadge
        icon={<StyledGuaranteeIcon />}
        label={label}
        isLaptop={isLaptop}
        isMobile={isMobile}
      />
    </Tippy>
  );
}

export default BadgeVersion;

const StyledBadge = styled(Badge)`
  color: ${colors.orange200};
  background: ${colors.orange300};
`;

const Content = styled.div`
  max-width: 280px;
  padding: 15px;

  background: ${colors.white};
  border: 0.5px solid ${colors.gray400};
  box-sizing: border-box;
  box-shadow: ${(props) => props.theme.blackShadow100};
  border-radius: 8px;

  font-weight: 300;
  font-size: 14px;
  line-height: 130%;
  color: ${colors.black};
`;

const StyledGuaranteeIcon = styled(GuaranteeIcon)`
  fill: ${colors.orange200};
`;
