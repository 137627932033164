import React from 'react';
import styled from 'styled-components';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';

import { getYouTubeId } from '@/utils/common';

import { ContentProps } from './types';

function Content({ video }: ContentProps) {
  return (
    <Component>
      <LiteYouTubeEmbed id={getYouTubeId(video)} title="YouTube Embed" />
    </Component>
  );
}

export default Content;

const Component = styled.div`
  width: 250px;
  height: 140px;
  box-shadow: ${(props) => props.theme.blackShadow100};
  border-radius: 4px;

  article {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
`;
