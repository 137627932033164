import React from 'react';
import styled, { css } from 'styled-components';
import Tippy from '@tippyjs/react';

import { isBrowser } from '@tager/web-core';

import { colors } from '@/constants/theme';
import InfoIcon from '@/assets/svg/info.svg';
import { media } from '@/utils/mixin';

import Content from './components/Content';

function CarApproved() {
  return (
    <Tippy
      content={<Content />}
      placement="bottom"
      interactive
      appendTo={isBrowser() ? document.body : 'parent'}
    >
      <Component>
        <Text>Approved</Text>
        <Icon>
          <InfoIcon />
        </Icon>
      </Component>
    </Tippy>
  );
}

export default CarApproved;

export const Component = styled.div`
  z-index: 25;
  cursor: default;
  display: flex;
  align-items: center;
  background: #c5a464;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 13px 50%);
  padding: 10px 8px 8px 25px;

  ${media.tablet(css`
    padding: 8px 6px 6px 20px;
  `)}
`;

export const Text = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.white};

  ${media.tablet(css`
    line-height: 16px;
  `)}
`;

export const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;

  svg {
    width: 16px;
    height: 16px;

    path:first-child {
      stroke: ${colors.white};
    }

    path:not(:first-child) {
      fill: ${colors.white};
    }

    ${media.tablet(css`
      width: 14px;
      height: 14px;
    `)}
  }
`;
