import styled from 'styled-components';

export const ContentContainerSmall = styled.div`
  max-width: 1280px;
  padding: 0 20px;
  margin: 0 auto;

  @media (min-width: 1200px) {
    padding: 0 40px;
  }
`;
