import React from 'react';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';

import { isBrowser } from '@tager/web-core';

import { colors } from '@/constants/theme';

import Checkbox from './components/Checkbox';
import { CompareCheckboxProps } from './types';

function CompareCheckbox({
  className,
  checked,
  onChange,
}: CompareCheckboxProps) {
  return (
    <Component className={className}>
      <Tippy
        content={
          <Info>{!checked ? 'В сравнение' : 'Удалить из сравнения'}</Info>
        }
        placement="bottom"
        zIndex={100}
        interactive
        appendTo={isBrowser() ? document.body : 'parent'}
      >
        <CheckBoxWrapper>
          <Checkbox checked={checked} onChange={onChange} />
        </CheckBoxWrapper>
      </Tippy>
    </Component>
  );
}

export default CompareCheckbox;

const Component = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  background: ${colors.white};
  border-radius: 6px;

  & > div > div {
    width: 13px;
    height: 13px;

    svg {
      width: 13px;
      height: 13px;
    }
  }
`;

const CheckBoxWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const Info = styled.div`
  padding: 10px;
  box-shadow: 0 4px 19px rgb(21 27 38 / 13%);
  border: 0.5px solid ${colors.gray400};
  border-radius: 8px;
  font-weight: 300;
  font-size: 14px;
  line-height: 130%;
  background: ${colors.white};
`;
