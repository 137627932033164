import { Nullable } from '@tager/web-core';

type ShareParams = {
  url: string;
  title: string;
  image: string;
  description: string;
};

export type SocialType =
  | 'vk'
  | 'facebook'
  | 'tg'
  | 'viber'
  | 'odnoklassniki'
  | 'twitter';

function openSharePopup(url: string): void {
  window.open(url, '', 'toolbar=0,status=0,width=626,height=436');
}

function getOpenGraphParam(param: string) {
  const metaElement = document.querySelector<HTMLMetaElement>(
    'meta[property="og:' + param + '"]'
  );
  return metaElement?.content ?? '';
}

const shareHandlers = {
  vk: (params: ShareParams) => {
    const searchParams = new URLSearchParams();
    searchParams.set('url', params.url);
    searchParams.set('title', params.title);
    searchParams.set('description', params.description);
    searchParams.set('image', params.image);
    searchParams.set('noparse', 'true');

    openSharePopup('http://vkontakte.ru/share.php?' + searchParams.toString());
  },
  odnoklassniki: (params: ShareParams) => {
    const searchParams = new URLSearchParams();
    searchParams.set('url', params.url);
    searchParams.set('title', params.title);
    searchParams.set('description', params.description);
    searchParams.set('imageUrl', params.image);
    openSharePopup('https://connect.ok.ru/offer?' + searchParams.toString());
  },

  viber: (params: ShareParams) => {
    const searchParams = new URLSearchParams();
    searchParams.set('url', `${params.url}`);
    searchParams.set('title', `${params.title}`);
    searchParams.set('description', `${params.description}`);
    searchParams.set('image', `${params.image}`);
    openSharePopup('viber://forward?text=' + params.url);
  },

  facebook: (params: { url: string }) => {
    const searchParams = new URLSearchParams();
    searchParams.set('u', params.url);

    openSharePopup(
      'https://www.facebook.com/sharer.php?' + searchParams.toString()
    );
  },
  tg: (params: ShareParams) => {
    const searchParams = new URLSearchParams();
    searchParams.set('url', `${params.url}`);
    searchParams.set('title', `${params.title}`);
    searchParams.set('description', `${params.description}`);
    searchParams.set('image', `${params.image}`);

    openSharePopup('https://telegram.me/share/url?' + searchParams.toString());
  },

  twitter: (params: ShareParams) => {
    const searchParams = new URLSearchParams();
    searchParams.set('url', params.url);
    searchParams.set('title', params.title);
    searchParams.set('description', params.description);
    searchParams.set('imageUrl', params.image);
    openSharePopup(
      'https://twitter.com/intent/tweet?' + searchParams.toString()
    );
  },
};

function share(
  socialType: SocialType,
  path: Nullable<string>,
  image?: Nullable<string>
) {
  if (!path) return null;

  if (socialType === 'facebook') {
    shareHandlers.facebook({
      url: document.location.origin + path,
    });
  }

  if (socialType === 'vk') {
    shareHandlers.vk({
      url: document.location.origin + path,
      title: getOpenGraphParam('title'),
      description: getOpenGraphParam('description'),
      image: getOpenGraphParam(image ?? 'image'),
    });
  }

  if (socialType === 'odnoklassniki') {
    shareHandlers.odnoklassniki({
      url: document.location.origin + path,
      title: getOpenGraphParam('title'),
      description: getOpenGraphParam('description'),
      image: getOpenGraphParam(image ?? 'image'),
    });
  }

  if (socialType === 'viber') {
    shareHandlers.viber({
      url: document.location.origin + path,
      title: getOpenGraphParam('title'),
      description: getOpenGraphParam('description'),
      image: getOpenGraphParam(image ?? 'image'),
    });
  }

  if (socialType === 'tg') {
    shareHandlers.tg({
      url: document.location.origin + path,
      title: getOpenGraphParam('title'),
      description: getOpenGraphParam('description'),
      image: getOpenGraphParam(image ?? 'image'),
    });
  }

  if (socialType === 'twitter') {
    shareHandlers.twitter({
      url: document.location.origin + path,
      title: getOpenGraphParam('title'),
      description: getOpenGraphParam('description'),
      image: getOpenGraphParam(image ?? 'image'),
    });
  }
}

export default share;
