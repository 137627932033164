import React from 'react';
import styled, { css } from 'styled-components';

import { formatNumber } from '@tager/web-core';

import { CarPriceProps } from '@/components/CarPrice/CarPrice.types';
import NoPrice from '@/components/NoPrice';
import { colors } from '@/constants/theme';

import PriceInfo from './components/PriceInfo';

function CarPrice({
  currency = 'BYN',
  discount,
  amount,
  credit,
  text,
  isMobile,
  isBig,
  tradeIn,
}: CarPriceProps) {
  const discountText = tradeIn
    ? 'Скидка предоставляется при покупке авто по программе от МТБ-Лизинг и (или) сдаче авто в Trade-In. Подробнее в разделе Акции.'
    : 'Размер экономии по сравнению с первоначальной ценой';
  const economyPrice = tradeIn ?? discount ?? 0;

  return (
    <Component>
      {amount ? (
        <>
          <Price hasEconomyPrice={!!discount} isBig={isBig} isMobile={isMobile}>
            {formatNumber(amount)}{' '}
            <Currency isMobile={isMobile}>{currency}</Currency>
          </Price>
          {economyPrice ? (
            <PriceInfoWrapper isMobile={isMobile}>
              <PriceInfo
                title="Экономия"
                price={economyPrice}
                infoType="economy"
                infoText={discountText}
                isBig={isBig}
                isMobile={isMobile}
              />
            </PriceInfoWrapper>
          ) : null}
          {credit && credit.value ? (
            <PriceInfoWrapper isMobile={isMobile}>
              <PriceInfo
                title="Ежемесячно"
                price={credit.value}
                infoType="monthly"
                infoText="Минимальная сумма ежемесячного платежа при покупке по одной из кредитных / лизинговых программ, предоставляемых партнерами продавца.
                Сочетание программ с другими акциями и предложениями уточняйте в отделе продаж."
                link={credit.link}
                isBig={isBig}
                isMobile={isMobile}
              />
            </PriceInfoWrapper>
          ) : null}
        </>
      ) : (
        text && <NoPrice value={text} isMobile={isMobile} />
      )}
    </Component>
  );
}

export default CarPrice;

export const Component = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const Price = styled.div<{
  hasEconomyPrice: boolean;
  isMobile?: boolean;
  isBig?: boolean;
}>`
  white-space: nowrap;
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  color: ${(props) => props.theme.black100};
  background: ${(props) =>
    props.hasEconomyPrice ? colors.yellow200 : 'transparent'};
  border-radius: 2px;
  padding: ${(props) =>
    props.hasEconomyPrice ? '2px 17px 1px 8px' : '2px 17px 1px 0'};
  margin-right: 5px;
  clip-path: polygon(
    0% 0%,
    calc(100% - 14px) 0%,
    100% 50%,
    calc(100% - 14px) 100%,
    0% 100%
  );

  ${({ isBig }) =>
    isBig &&
    css`
      font-size: 40px;
      line-height: 47px;
    `}

  ${({ isMobile, hasEconomyPrice }) =>
    isMobile &&
    css`
      padding: ${() => (hasEconomyPrice ? '2px 9px 2px 8px' : '2px 9px 2px 0')};
      font-size: 22px;
      line-height: 26px;
      clip-path: polygon(
        0% 0%,
        calc(100% - 9px) 0%,
        100% 50%,
        calc(100% - 9px) 100%,
        0% 100%
      );
    `}
`;

export const PriceInfoWrapper = styled.div<{ isMobile?: boolean }>`
  &:not(:last-child) {
    margin-right: 7px;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      &:not(:last-child) {
        margin-right: 5px;
      }
    `}
`;

export const Currency = styled.span<{ isMobile?: boolean }>`
  font-size: 18px;
  line-height: 21px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      font-size: 11px;
      line-height: 12.89px;
    `}
`;
