import React from 'react';
import styled from 'styled-components';

import { CssSnippet } from '@tager/web-components';

import { colors } from '@/constants/theme';

import { ContentProps } from './types';

function Content({ text, customStyles }: ContentProps) {
  return (
    <Component customStyles={customStyles}>
      <ComponentContainer dangerouslySetInnerHTML={{ __html: text }} />
    </Component>
  );
}

export default Content;

const Component = styled.div<{ customStyles?: CssSnippet }>`
  background: ${colors.white};
  border: 0.5px solid ${colors.gray400};
  box-shadow: ${(props) => props.theme.blackShadow100};
  border-radius: 8px;

  ${(props) => props.customStyles && props.customStyles}
`;

const ComponentContainer = styled.div`
  max-width: 280px;
  padding: 15px;

  font-weight: 300;
  font-size: 14px;
  line-height: 130%;
  color: ${colors.black};

  span {
    font-weight: 500;
  }
`;
