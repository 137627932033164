import React from 'react';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';

import { isBrowser } from '@tager/web-core';

import CarTag from './components/CarTag';
import { CarTagsProps } from './CarTags.types';
import VinTag from './components/VinTag';
import InfoText from './components/InfoText';

function CarTags({ tags, VIN, isMobile, isLaptop, className }: CarTagsProps) {
  return (
    <Component className={className}>
      {tags.map(({ infoText, ...tag }, index) => {
        return (
          <Tippy
            key={index}
            content={infoText && <InfoText text={infoText} />}
            placement="bottom"
            interactive
            appendTo={isBrowser() ? document.body : 'parent'}
          >
            <CarTag {...tag} isLaptop={isLaptop} isMobile={isMobile} />
          </Tippy>
        );
      })}
      {VIN && <VinTag vin={VIN} isLaptop={isLaptop} isMobile={isMobile} />}
    </Component>
  );
}

export default CarTags;

const Component = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
